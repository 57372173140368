<div class="card animation-fade-in">
    <!-- [ngClass]="{'box': isMobile}" -->
    <!-- <header *ngIf="isMobile"> 
        <i *ngIf="!isInBooking" class="icon credit-card"></i>
        <div *ngIf="!EditCard;else editc" >
          <p *ngIf="paymentStripe == 'false'">Add a new card</p>
          <p *ngIf="paymentStripe == 'true'">Enter card details</p>
        </div>
        <ng-template #editc>
            <p>Edit card details</p>
        </ng-template>
       
     </header> -->

    <form class="card-form" [formGroup]="cardForm" (ngSubmit)="onFormSubmit()">
        <div class="field-wrapper custom-input-wrapper holder-name">
            <p>Card holder full name</p>
            <input matInput type="text" formControlName="holder" class="input-border long_input" placeholder="Add card holder full name">
            <div class="error" *ngIf="getFormField('holder').errors?.required && cardForm.controls.holder.touched">
                Cardholder is required
            </div>
        </div>

        <div class="field-wrapper custom-input-wrapper card-number">
            <p>Card number</p>
            <input matinput type="text" formControlName="number" class="input-border long_input" placeholder="Credit or debit card number">
            <div class="error" *ngIf="getFormField('number').hasError('required') && cardForm.controls.number.touched">
                Card number is required
            </div>
            <div class="error" *ngIf="getFormField('number').hasError('pattern')">
                Invalid card number
            </div>
            <!-- <div class="error" *ngIf="getFormField('number').hasError('min')">
                Card number should be at least 14 digits
            </div>
            <div class="error" *ngIf="getFormField('number').hasError('max')">
                Card number should not be more than 16 digits
            </div> -->
        </div>

        <div class="field-wrapper custom-input-wrapper month">
            <p>Expiry date</p>
            <input matInput formControlName="month" type="number"  inputmode="numeric" name="month" placeholder="MM" (keyup)="keytab($event)"  class="input-border">
            <div class="error" *ngIf="getFormField('month').hasError('required') && cardForm.controls.month.touched">
                Required
            </div>
            <div class="error" *ngIf="getFormField('month').hasError('min') || getFormField('month').hasError('max') ">
                Invalid
            </div>
        </div>

        <div class="field-wrapper custom-input-wrapper month">
            <input matInput formControlName="year" type="number"  inputmode="numeric" name="year" placeholder="YY" id="year" (keyup)="keytabNext($event)"  class="input-border">
            <div class="error" *ngIf="getFormField('year').hasError('required') && cardForm.controls.year.touched">
                Required
            </div>
            <div class="error error-top" *ngIf="getFormField('year').value && getFormField('year').value.toString().length > 2" >
                Invalid format please put "YY"
            </div>
            <div class="error" *ngIf="getFormField('year').hasError('min') || getFormField('year').hasError('max') ">
                Invalid
            </div>
        </div>

        <div class="field-wrapper custom-input-wrapper cvc">
            <p>CVV</p>
            <input matInput formControlName="cvc" type="number"  inputmode="numeric" name="cvc" placeholder="" id="cvc" class="input-border">
            <div class="error" *ngIf="getFormField('cvc').hasError('required') && cardForm.controls.cvc.touched">
                Required
            </div>
            <div class="error" *ngIf="getFormField('cvc').hasError('minLength') || getFormField('cvc').hasError('minLength')">
                Invalid
            </div>
        </div>
        <div class="pay"> 
            <span>Pay securely with </span>
            <img  src="assets/images/cards/visa.png" alt="visa">
            <img  src="assets/images/cards/mastercard.png" alt="mastercard">
            <img  src="assets/images/cards/amex.png" alt="amex"> 
        </div>
        <div class="card-footer">
            <img src="/assets/icons/lock.svg" />
            <span>Your card details will be securely encrypted and stored with Stripe</span>
        </div>

        <hr>
        <div>
            <p class="delivery-policy">Delivery policy</p>
            <p class="delivery-policy-desc">Delivery is FREE for orders over £30. Typically, this is a suit and three shirts, or a dress and a top. You will be charged a delivery fee for orders under the £30 threshold.</p>
        </div>
        <button type="submit" [disabled]="!isValid" class="btn {{btnCssClasses}}"  [id]="bookingPayment" >{{submitButtonText}}</button> 

    </form>
</div>
